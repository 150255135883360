// Types for case summary config
export const CASE_SUMMARY_CONFIG_CM_REQUEST = "CASE_SUMMARY_CONFIG_CM_REQUEST";
export const CASE_SUMMARY_CONFIG_CM_RECEIVE = "CASE_SUMMARY_CONFIG_CM_RECEIVE";
export const CASE_SUMMARY_CONFIG_CM_FAILURE = "CASE_SUMMARY_CONFIG_CM_FAILURE";
export const RESET_CASE_SUMMARY_CONFIG_CM = "RESET_CASE_SUMMARY_CONFIG_CM";

// Types for case summary data
export const CASE_SUMMARY_DATA_CM_REQUEST = "CASE_SUMMARY_DATA_CM_REQUEST";
export const CASE_SUMMARY_DATA_CM_RECEIVE = "CASE_SUMMARY_DATA_CM_RECEIVE";
export const CASE_SUMMARY_DATA_CM_FAILURE = "CASE_SUMMARY_DATA_CM_FAILURE";
export const RESET_CASE_SUMMARY_DATA_CM = "RESET_CASE_SUMMARY_DATA_CM";

// Types for case data store in redux
export const CASE_DATA_CM_UPDATE = "CASE_DATA_CM_UPDATE";
export const RESET_CASE_DATA_CM = "RESET_CASE_DATA_CM";

// Types for information governance form data
export const INFORMATION_GOVERNANCE_DATA_CM_REQUEST = "INFORMATION_GOVERNANCE_DATA_CM_REQUEST";
export const INFORMATION_GOVERNANCE_DATA_CM_RECEIVE = "INFORMATION_GOVERNANCE_DATA_CM_RECEIVE";
export const INFORMATION_GOVERNANCE_DATA_CM_FAILURE = "INFORMATION_GOVERNANCE_DATA_CM_FAILURE";
export const RESET_INFORMATION_GOVERNANCE_DATA_CM = "RESET_INFORMATION_GOVERNANCE_DATA_CM";

// Types for information governance access request
export const IGAPI_DATA_REQUEST = "IGAPI_DATA_REQUEST";
export const IGAPI_DATA_RECEIVE = "IGAPI_DATA_RECEIVE";
export const IGAPI_DATA_FAILURE = "IGAPI_DATA_FAILURE";
export const RESET_IGAPI_DATA = "RESET_IGAPI_DATA";

// Types for fetch overview reference
export const FETCH_OVERVIEWREFERENCE_CM_REQUEST = "FETCH_OVERVIEWREFERENCE_CM_REQUEST";
export const FETCH_OVERVIEWREFERENCE_CM_RECEIVE = "FETCH_OVERVIEWREFERENCE_CM_RECEIVE";
export const FETCH_OVERVIEWREFERENCE_CM_FAILURE = "FETCH_OVERVIEWREFERENCE_CM_FAILURE";
export const RESET_FETCH_OVERVIEWREFERENCE_CM = "RESET_FETCH_OVERVIEWREFERENCE_CM";

// Types for fetch overview reference
export const FETCH_OVERVIEWREFERENCE_ACCESS_CM_REQUEST = "FETCH_OVERVIEWREFERENCE_ACCESS_CM_REQUEST";
export const FETCH_OVERVIEWREFERENCE_ACCESS_CM_RECEIVE = "FETCH_OVERVIEWREFERENCE_ACCESS_CM_RECEIVE";
export const FETCH_OVERVIEWREFERENCE_ACCESS_CM_FAILURE = "FETCH_OVERVIEWREFERENCE_ACCESS_CM_FAILURE";
export const RESET_FETCH_OVERVIEWREFERENCE_ACCESS_CM = "RESET_FETCH_OVERVIEWREFERENCE_ACCESS_CM";

//Types for cs-feedback-columns
export const FEEDBACK_CS_COLUMNS_REQUEST = "FEEDBACK_CS_COLUMNS_REQUEST";
export const FEEDBACK_CS_COLUMNS_RECEIVE = "FEEDBACK_CS_COLUMNS_RECEIVE";
export const FEEDBACK_CS_COLUMNS_FAILURE = "FEEDBACK_CS_COLUMNS_FAILURE";
export const RESET_FEEDBACK_CS_COLUMNS = "RESET_FEEDBACK_CS_COLUMNS";

//Types for cs-feedback 
export const FEEDBACK_CS_REQUEST = "FEEDBACK_CS_REQUEST";
export const FEEDBACK_CS_RECEIVE = "FEEDBACK_CS_RECEIVE";
export const FEEDBACK_CS_FAILURE = "FEEDBACK_CS_FAILURE";
export const RESET_FEEDBACK_CS = "RESET_FEEDBACK_CS";

//Types for cs-feedback-add
export const FEEDBACK_CS_ADD_REQUEST = "FEEDBACK_CS_ADD_REQUEST";
export const FEEDBACK_CS_ADD_RECEIVE = "FEEDBACK_CS_ADD_RECEIVE";
export const FEEDBACK_CS_ADD_FAILURE = "FEEDBACK_CS_ADD_FAILURE";
export const RESET_FEEDBACK_CS_ADD = "RESET_FEEDBACK_CS_ADD";

//Types for cs-feedback-edit
export const FEEDBACK_CS_EDIT_REQUEST = "FEEDBACK_CS_EDIT_REQUEST";
export const FEEDBACK_CS_EDIT_RECEIVE = "FEEDBACK_CS_EDIT_RECEIVE";
export const FEEDBACK_CS_EDIT_FAILURE = "FEEDBACK_CS_EDIT_FAILURE";
export const RESET_FEEDBACK_CS_EDIT = "RESET_FEEDBACK_CS_EDIT";


//Types for cs-feedback-delete
export const FEEDBACK_CS_DELETE_REQUEST = "FEEDBACK_CS_DELETE_REQUEST";
export const FEEDBACK_CS_DELETE_RECEIVE = "FEEDBACK_CS_DELETE_RECEIVE";
export const FEEDBACK_CS_DELETE_FAILURE = "FEEDBACK_CS_DELETE_FAILURE";
export const RESET_FEEDBACK_CS_DELETE = "RESET_FEEDBACK_CS_DELETE";
// Types for fetch overview reference
export const CASE_DETAIL_ALL_REQUEST = "CASE_DETAIL_ALL_REQUEST";
export const CASE_DETAIL_ALL_RECEIVE = "CASE_DETAIL_ALL_RECEIVE";
export const CASE_DETAIL_ALL_FAILURE = "CASE_DETAIL_ALL_FAILURE";
export const RESET_CASE_DETAIL_ALL = "RESET_CASE_DETAIL_ALL";

// Types for fetch overview reference
export const ADD_OUTCOME_REQUEST = "ADD_OUTCOME_REQUEST";
export const ADD_OUTCOME_RECEIVE = "ADD_OUTCOME_RECEIVE";
export const ADD_OUTCOME_FAILURE = "ADD_OUTCOME_FAILURE";
export const RESET_ADD_OUTCOME = "RESET_ADD_OUTCOME";

// Types for fetch overview reference
export const ADD_EDIT_POPUP_REQUEST = "ADD_EDIT_POPUP_REQUEST";
export const ADD_EDIT_POPUP_RECEIVE = "ADD_EDIT_POPUP_RECEIVE";
export const ADD_EDIT_POPUP_FAILURE = "ADD_EDIT_POPUP_FAILURE";
export const RESET_ADD_EDIT_POPUP = "RESET_ADD_EDIT_POPUP";

// Types for fetch overview reference
export const PICK_LIST_DATA_REQUEST = "PICK_LIST_DATA_REQUEST";
export const PICK_LIST_DATA_RECEIVE = "PICK_LIST_DATA_RECEIVE";
export const PICK_LIST_DATA_FAILURE = "PICK_LIST_DATA_FAILURE";
export const RESET_PICK_LIST_DATA = "RESET_PICK_LIST_DATA";

// Types for fetch overview reference
export const DELETE_OUTCOME_DATA_REQUEST = "DELETE_OUTCOME_DATA_REQUEST";
export const DELETE_OUTCOME_DATA_RECEIVE = "DELETE_OUTCOME_DATA_RECEIVE";
export const DELETE_OUTCOME_DATA_FAILURE = "DELETE_OUTCOME_DATA_FAILURE";
export const RESET_DELETE_OUTCOME_DATA = "RESET_DELETE_OUTCOME_DATA";

// Types for fetch overview reference
export const GET_EDIT_OUTCOME_DATA_REQUEST = "GET_EDIT_OUTCOME_DATA_REQUEST";
export const GET_EDIT_OUTCOME_DATA_RECEIVE = "GET_EDIT_OUTCOME_DATA_RECEIVE";
export const GET_EDIT_OUTCOME_DATA_FAILURE = "GET_EDIT_OUTCOME_DATA_FAILURE";
export const RESET_GET_EDIT_OUTCOME_DATA = "RESET_GET_EDIT_OUTCOME_DATA";

// Types for fetch overview reference
export const EDIT_OUTCOME_DATA_REQUEST = "EDIT_OUTCOME_DATA_REQUEST";
export const EDIT_OUTCOME_DATA_RECEIVE = "EDIT_OUTCOME_DATA_RECEIVE";
export const EDIT_OUTCOME_DATA_FAILURE = "EDIT_OUTCOME_DATA_FAILURE";
export const RESET_EDIT_OUTCOME_DATA = "RESET_EDIT_OUTCOME_DATA";

// Types for fetch overview reference
export const FETCH_ALL_ENGAGMENT_TRACKER_REQUEST = "FETCH_ALL_ENGAGMENT_TRACKER_REQUEST";
export const FETCH_ALL_ENGAGMENT_TRACKER_RECEIVE = "FETCH_ALL_ENGAGMENT_TRACKER_RECEIVE";
export const FETCH_ALL_ENGAGMENT_TRACKER_FAILURE = "FETCH_ALL_ENGAGMENT_TRACKER_FAILURE";
export const RESET_FETCH_ALL_ENGAGMENT_TRACKER = "RESET_FETCH_ALL_ENGAGMENT_TRACKER";

// Types for fetch overview reference
export const ADD_EDIT_ENGAGMENT_POPUP_REQUEST = "ADD_EDIT_ENGAGMENT_POPUP_REQUEST";
export const ADD_EDIT_ENGAGMENT_POPUP_RECEIVE = "ADD_EDIT_ENGAGMENT_POPUP_RECEIVE";
export const ADD_EDIT_ENGAGMENT_POPUP_FAILURE = "ADD_EDIT_ENGAGMENT_POPUP_FAILURE";
export const RESET_ADD_EDIT_ENGAGMENT_POPUP = "RESET_ADD_EDIT_ENGAGMENT_POPUP";

// Types for fetch overview reference
export const ADD_ENGAGMENT_REQUEST = "ADD_ENGAGMENT_REQUEST";
export const ADD_ENGAGMENT_RECEIVE = "ADD_ENGAGMENT_RECEIVE";
export const ADD_ENGAGMENT_FAILURE = "ADD_ENGAGMENT_FAILURE";
export const RESET_ADD_ENGAGMENT = "RESET_ADD_ENGAGMENT";

// Types for fetch overview reference
export const GET_EDIT_ENGAGMENT_DATA_REQUEST = "GET_EDIT_OUTCOME_DATA_REQUEST";
export const GET_EDIT_ENGAGMENT_DATA_RECEIVE = "GET_EDIT_OUTCOME_DATA_RECEIVE";
export const GET_EDIT_ENGAGMENT_DATA_FAILURE = "GET_EDIT_OUTCOME_DATA_FAILURE";
export const RESET_GET_EDIT_ENGAGMENT_DATA = "RESET_GET_EDIT_OUTCOME_DATA";

// Types for fetch overview reference
export const EDIT_ENGAGMENT_DATA_REQUEST = "EDIT_ENGAGMENT_DATA_REQUEST";
export const EDIT_ENGAGMENT_DATA_RECEIVE = "EDIT_ENGAGMENT_DATA_RECEIVE";
export const EDIT_ENGAGMENT_DATA_FAILURE = "EDIT_ENGAGMENT_DATA_FAILURE";
export const RESET_EDIT_ENGAGMENT_DATA = "RESET_EDIT_ENGAGMENT_DATA";

// Types for fetch overview reference
export const DELETE_ENGAGMENT_DATA_REQUEST = "DELETE_ENGAGMENT_DATA_REQUEST";
export const DELETE_ENGAGMENT_DATA_RECEIVE = "DELETE_ENGAGMENT_DATA_RECEIVE";
export const DELETE_ENGAGMENT_DATA_FAILURE = "DELETE_ENGAGMENT_DATA_FAILURE";
export const RESET_DELETE_ENGAGMENT_DATA = "RESET_DELETE_ENGAGMENT_DATA";

// Types for fetch overview reference
export const PICK_LIST_ENGAGMENT_DATA_REQUEST = "PICK_LIST_ENGAGMENT_DATA_REQUEST";
export const PICK_LIST_ENGAGMENT_DATA_RECEIVE = "PICK_LIST_ENGAGMENT_DATA_RECEIVE";
export const PICK_LIST_ENGAGMENT_DATA_FAILURE = "PICK_LIST_ENGAGMENT_DATA_FAILURE";
export const RESET_PICK_LIST_ENGAGMENT_DATA = "RESET_PICK_LIST_ENGAGMENT_DATA";

export const HHC_REQUEST = "HHC_REQUEST";
export const HHC_RECEIVE = "HHC_RECEIVE";
export const HHC_FAILURE = "HHC_FAILURE";
export const RESET_HHC = "RESET_HHC";

export const DOWNLOAD_ASSETS_REQUEST = "DOWNLOAD_ASSETS_REQUEST";
export const DOWNLOAD_ASSETS_RECEIVE = "DOWNLOAD_ASSETS_RECEIVE";
export const DOWNLOAD_ASSETS_FAILURE = "DOWNLOAD_ASSETS_FAILURE";
export const RESET_DOWNLOAD_ASSETS = "RESET_DOWNLOAD_ASSETS";

export const IFRAME_REQUEST = "IFRAME_REQUEST";
export const IFRAME_RECEIVE = "IFRAME_RECEIVE";
export const IFRAME_FAILURE = "IFRAME_FAILURE";
export const RESET_IFRAME = "RESET_IFRAME";