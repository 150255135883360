import { useEffect, useState } from "react";
import { closeIcon } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchIframeData, resetfetchIframeData } from "../store/actions";
import { encodeFormData } from "../../../utils";
import axios from "axios";

// Functional component for Information Governance
const IFrameWrapper = (props: any) => {
    const dispatch = useDispatch();

    const [iframeData, setIframeData] = useState<any>(null);
    const [iframeBlobData, setIframeBlobData] = useState<any>(null);
    const [isScvIdRequired, setIsScvIdRequired] = useState(false)

    const iframeConfigData = useSelector((state: any) => state.iframeConfigData);

    useEffect(() => {
        if (typeof (props.param.scvid) === "string") {
            fetchIframeUrl()
        } else {
            console.log(props.param)
        }
    }, [])

    useEffect(() => {
        if (iframeConfigData.isSuccess && iframeConfigData.data !== null) {
            if (iframeConfigData.data?.headerTokenTag) {
                fetchIframeUrlBlog(iframeConfigData.data)
            }
            setIframeData(iframeConfigData.data);

            dispatch(resetfetchIframeData());
        } else if (iframeConfigData.isError) {

            setIframeBlobData(null);
            setIframeData(null);

            dispatch(resetfetchIframeData());
        }
    }, [iframeConfigData])


    const fetchIframeUrl = () => {
        try {
            setIframeBlobData(null);
            setIframeData(null);

            dispatch(fetchIframeData({
                url: props.data.iframeEndPoint.endpointUrl.replace("/api", ""),
                token: props.token,
                param: encodeFormData(props.param)
            }));
        } catch (e: any) {
            console.log("error", e);
        }
    }

    const fetchIframeUrlBlog = async (req: any) => {
        setIframeBlobData(null);
        
        const response = await axios.get(req.url, {
            headers: {
                Authorization: `Bearer ${req.headerToken}`,
                Accept: "*/*"
            }
        });

        if (response.status === 200) {
            setIframeBlobData(URL.createObjectURL(new Blob([response.data], { type: "text/html" })));
            // setIframeBlobData(response.data);
        }

    }


    return (<div className="addoutcome-popup">
        <div
            className="modal fade show"
            id="exampleModal"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog" role="document" style={{ width: "80%" }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                        <button className="btn btn-transparent close" onClick={() => {
                            props.onSubmit("", null);
                        }} >
                            <img src={closeIcon} alt="close" />
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            iframeData !== null && <iframe src={iframeData?.headerTokenTag ? iframeBlobData : iframeData.url} style={{ width: "100%", height: "85vh" }}
                                allowFullScreen
                                title="iframe"
                            ></iframe>
                        }

                    </div>
                </div>
            </div>
        </div>
    </div>)
};

export default IFrameWrapper;
